import { useMemo } from "react";
export const useQuestionsWithAnswersForStudents = () => {
	return useMemo(
		() => [
			{
				id: 0,
				question: "როგორ უნდა დაწეროს მოსწავლემ დავალება?",
				link: "",
				answer: 'მასწავლებლის მიერ გაგზავნილ ბმულზე დაწკაპებით მოსწავლე მარტივად, სახელის და გვარის მითითებით, ასრულებს დავალებას და ღილაკზე "დავალების გაგზავნა" დაწკაპებით მასწავლებელთან ავტომატურად გადაგზავნილია მის მიერ შესრულებული დავალება. მოსწავლეს, სურვილისამებრ, შეუძლია, დარეგისტრირდეს, თუმცა რეგისტრაცია სავალადებულო არ არის',
			},
			{
				id: 1,
				question:
					"როგორ ნახოს მოსწავლემ მასწავლებლის მიერ გაგზავნილი უკუკავშირი და როგორია ავტომატური უკუკავშირი?",
				answer: 'მას შემდეგ, რაც მასწავლებელი დაწკაპებს ღილაკს "უკუკავშირის გაგზავნა მოსწავლეებისთვის", მოსწავლე იმავე ბმულით, რომელზეც შეასრულა დავალება, ნახულობს უკუკავშირს.',
			},
		],
		[]
	);
};

export const useQuestionsWithAnswersForTeachers = () => {
	return useMemo(
		() => [
			{
				id: 0,
				question: "როგორ გავუგზავნო დავალება მოსწავლეებს?",
				link: "",
				answer: 'მას შემდეგ რაც გავივლი რეგისტრაციას და შევალ მთავარ გვერდზე, პირდაპირ ვხვდები გვერდზე, საიდანაც იქმნება პირველი დავალება; ვირჩევ სასურველ კლასს, ვირჩევ სასურველ საგანს, ვირჩევ სასურველ თემას. ამის შემდეგ გამოდის გვერდი, სადაც არის ღილაკი "კოპირება". ღილაკზე "კოპირება" დაწკაპებით დავალების ბმული დაკოპირებულია. ამის შემდეგ, ბმულს "Paste" ფუნქციით ვუგზავნი მოსწავლეებს ან თიმსის ჩატში, ან მესენჯერის ჩატში ან ნებისმიერი საკომუნიკაციო არხით. მოსწავლეებს მოცემულ ბმულზე უკვე დახვდებათ დავალება.',
			},

			{
				id: 3,
				question:
					"შემიძლია თუ არა ჩემი შედგენილი ტესტები ავტვირთო და გავუგზავნო მოსწავლეებს?",
				answer: "მასწავლებელს შეუძლია მის მიერ შედგენილი ტესტები კონკრეტული კლასის, საგნის, თემის სათაურის მითითებით მოგვაწოდოს ელ.ფოსტაზე info@tvschool.ge  და ჩვენ 24 საათში ავტვირთავთ ტესტებს! ",
			},
			{
				id: 4,
				question:
					"როგორ ჩავუგდო მოსწავლეებს დავალების ბმული თიმსში ან მესენჯერში?",

				answer: "როდესაც აირჩევთ სასურველ დავალებას, ღილაკზე 'კოპირება' დაკლიკების შემდეგ ხსნით თიმსისა ან მესენჯერის ჩატს, სადაც მიმოწერა გაქვთ მოსწავლეებთან და \"Paste\" ფუნქციით უგზავნით ლინკს. ",
			},
			{
				id: 5,
				question:
					"როგორ დავრწმუნდე, რომ ლინკი რომელსაც მოსწავლეებს ვუგზავნი, უშეცდომოა და ზუსტად არის მასზე ატვირთული ჩემი დავალება?",
				answer: 'მას შემდეგ რაც შექმნით დავალებას და დააწკაპებთ ღილაკს კოპირება, "Paste" ფუნქციით შეგიძლიათ ლინკი გახსნათ ახალ ფანჯარაში. ეს ის დავალება იქნება, რომელსაც მოსწავლეები მიიღებენ',
			},
			{
				id: 6,
				question:
					"როგორ გავუგზავნო დავალება ახალ კლასს, როცა რამდენიმე კლასს ვასწავლი?",
				answer: 'ასეთ დროს აკლიკებთ ღილაკს "დავალების გაგზავნა" ახალი კლასისთვის',
			},
			{
				id: 7,
				question: "როგორ უნდა დაწეროს მოსწავლემ დავალება?",
				answer: "მასწავლებლის მიერ გაგზავნილ ბმულზე დაწკაპებით მოსწავლე მარტივად, სახელის და გვარის მითითებით, ასრულებს დავალებას და ღილაკზე დავალების გაგზავნა დაწკაპებით მასწავლებელთან ავტომატურად გადაგზავნილია მის მიერ შესრულებული დავალება. მოსწავლეს, სურვილისამებრ, შეუძლია, დარეგისტრირდეს, თუმცა რეგისტრაცია სავალადებულო არ არის. ",
			},
			{
				id: 8,
				question:
					"როგორ ვნახო მოსწავლეების მიერ შესრულებული დავალება ?",
				answer: "მას შემდეგ რაც მოსწავლეებს გავუგზავნი დავალებას, მთავარ გვერდზე გამომიჩნდება შესაბამისი ჯგუფი (მაგალითად, I კლასი მათემატიკა). ამ განყოფილებაში გაგზავნილი დავალებებზე დაწკაპებით, გამოჩნდება ჩემ მიერ გაგზავნილი დავალება/დავალებები. სასურველი დავალების გასწვრივ ქვემოთ მიმართული ისრით შესაძლებელია დავალების დამწერი მოსწავლეებისა და მათი შედეგების ჩამოშლა. კონკრეტული მოსწავლის სახელის და გვარის გასწვრივ ნახვის ღილაკზე დაწკაპებით შემიძლია, ვნახო ამ მოსწავლის ნაწერი. ",
			},
			{
				id: 9,
				question: "როგორ გავუგზავნო მოსწავლეს უკუკავშირი ? ",
				answer: "გაგზავნილ დავალებებში, კონკრეტული დავალების ქვემოთ, არის ღილაკი უკუკავშირის გაგზავნა მოსწავლეებისთვის . მოცემულ ღილაკზე დაწკაპებით თქვენ მოსწავლეს აძლევთ უფლებას, ნახოს დავალების ავტომატური უკუკავშირი - სწორი და არასწორი პასუხები, შეკითხვების ახსნა-განმარტებები(არსებობის შემთხვევაში). ამისთვის მოსწავლემ უნდა გამოიყენოს იგივე ბმული, რომელიც დავალების შესასრულებლად გამოიყენა. ",
			},
			{
				id: 10,
				question:
					"როგორ ნახოს მოსწავლემ მასწავლებლის მიერ გაგზავნილი უკუკავშირი და როგორია ავტომატური უკუკავშირი?",
				answer: "მას შემდეგ, რაც მასწავლებელი დაწკაპებს ღილაკს უკუკავშირის გაგზავნა მოსწავლეებისთვის, მოსწავლე იმავე ბმულით, რომელზეც შეასრულა დავალება, ნახულობს უკუკავშირს.",
			},
			{
				id: 11,
				question:
					"თუ ჩემთვის სასურველ კლასში არ არის ჩემთვის სასურველი თემის დავალებები, როგორ მოვიქცე? ",
				answer: 'მას შემდეგ რაც აირჩევს სასურველ კლასსა და საგანს, პედაგოგს შესაძლებლობა ქვს მოითხოვოს "დავალების დამატება" ამავე სახელწოდების ღილაკის მეშვეობით, მიუთითოს რომელ თემაზე/თემებზე ისურვებდა დავალების დამატებას. შეგიძლიათ, მიუთითოთ სახელმძღვანელო, რომლიდანაც ისურვებდით დავალებებს. თქვენ მიერ მოთხოვნილ თემებზე დავალებები 2 დღის განმავლობაში დაემატება და დამატების შესახებ ინფორმაცია მოკლე ტექსტური შეტყობინებით გეცნობებათ.',
			},
			{
				id: 12,
				question: "თუ დავალებაში შეცდომაა, როგორ გაცნობოთ?",
				answer: 'ყოველი შეკითხვის გასწვრივ არის ღილაკი "შეცდომაა?" ამ ღილაკზე დაკლიკებით ადმინისტრაცია მიიღებს თქვენს რეკომენდაციას და შეკითხვა გასწორდება 24 საათში',
			},
			{
				id: 13,
				question:
					"თუ შემთხვევით გამეგზავნა დავალება ან უკუკავშირი შემიძლია გამოვასწორო?",
				answer: "ასეთ შემთხვევაში გთხოვთ დაგვიკავშირდეთ ცხელ ხაზზე და ტექნიკური ჯგუფი მყისიერად დაგეხმარებათ.",
			},
			{
				id: 14,
				question:
					"შემიძლია თუ არა დავალებების გაგზავნა მობილური ტელეფონით? ",
				answer: "პლატფორმის გამოყენება მობილურით შესაძლებელია, თუმცა 10 მაისამდე მასწავლებლისთვის უფრო კომფორტულია კომპიუტერის გამოყენება.",
			},
		],
		[]
	);
};

export const useQuestionsWithAnswersForLithuanian = () => {
	return useMemo(
		() => [
			{
				id: 0,
				question:
					"Kur galiu rasti esamus namų darbus, kuriuos galiu iš karto išsiųsti savo mokiniams?",
				link: "",
				answer: "Sukūrę savo paskyrą, būsite nukreipti tiesiai į puslapį, kuriame galėsite pasirinkti klasę ir kursą. Tada spustelėkite „Esami užduotys“, pasirinkite temą ir spustelėkite „Siųsti užduotį“. Nukopijuokite nuorodą ir išsiųskite ją mokiniams per Teams, Messenger ir kt. Jei nerandate norimos užduoties, galite lengvai ją užsisakyti spustelėję „Užsisakyti namų darbus“. Tiesiog nurodykite norimos užduoties temą ir kursą, o mūsų komanda įkels ją į svetainę per 48 valandas. Jei norite patys sukurti namų darbus, tiesiog pasirinkite kursą ir spustelėkite „Mano užduotys“, kur galėsite sukurti naują užduotį ir išsiųsti ją mokiniams.",
			},

			{
				id: 1,
				question: "Kaip išsiųsti esamus namų darbus savo mokiniams?",
				answer: "Pirmiausia susikurkite savo paskyrą. Prisijungę būsite nukreipti tiesiai į puslapį, kuriame galite pasirinkti klasę, kuriai norite sukurti namų darbus. Pasirinkę klasę, pasirinkite kursą ir pamatysite „Esami užduotys“. Tada pasirinkite dalyką ir paspauskite „Išsaugoti mano užduotį“. Galiausiai paspauskite mygtuką „kopijuoti“, nukopijuokite nuorodą ir išsiųskite ją savo mokiniams per Teams, Messenger ir kt.",
			},
			{
				id: 11,
				question: "Ar galiu užsisakyti norimos temos namų darbus?",
				answer: "Taip, galite lengvai tai padaryti. Tiesiog spustelėkite „Užsisakyti namų darbus“, ir mūsų komanda įkels juos į svetainę per 48 valandas.",
			},
			{
				id: 12,
				question: "Kaip galiu pats sukurti namų darbus?",
				link: "",
				answer: "Pirmiausia susikurkite savo paskyrą. Prisijungę būsite nukreipti tiesiai į puslapį, kuriame galite pasirinkti klasę, kuriai norite sukurti namų darbus. Pasirinkę klasę, pasirinkite kursą ir pamatysite „Mano užduotys“. Tada spustelėkite „Sukurti naują užduotį“ ir pasirinkite tarp „kelių pasirinkimų klausimo“ ir „atviro klausimo“. Sukurkite užduotį ir paspauskite „Išsaugoti mano užduotį“. Galiausiai paspauskite mygtuką „kopijuoti“, nukopijuokite nuorodą ir išsiųskite ją savo mokiniams per Teams, Messenger ir kt.",
			},

			{
				id: 2,
				question: "Kaip išsiųsti namų darbus per Teams ar Messenger?",
				answer: "Kai sukursite užduotį, paspauskite mygtuką „kopijuoti“. Atidarykite Teams ar Messenger pokalbį, įklijuokite nuorodą ir išsiųskite ją savo mokiniams.",
			},
			{
				id: 3,
				question: "Kaip galiu pateikti atsiliepimą savo mokiniams?",
				answer: "Eikite į „išsiųstos namų darbų užduotys“ ir paspauskite „Uždaryti užduotį ir išsiųsti atsiliepimą“. Mokiniai galės peržiūrėti atsiliepimą per tą pačią nuorodą, kur atliko užduotį.",
			},
			{
				id: 4,
				question: "Kaip mokinys gali atlikti užduotį?",
				answer: "Per nuorodą, kurią atsiuntė mokytojas, mokinys įveda savo vardą ir iškart pildo namų darbų užduotį. Kai užduotis baigta, jis/ji paspaudžia „išsiųsti užduotį“, kad ją nusiųstų mokytojui. Mokinys gali prisiregistruoti, kad išsaugotų pateiktas užduotis, tačiau taip pat galima atlikti užduotis be paskyros.",
			},
			{
				id: 5,
				question: "Kur mokinys gali peržiūrėti mokytojo atsiliepimą?",
				answer: "Kai mokytojas uždaro užduotį ir pateikia atsiliepimą, jis pasirodys toje pačioje nuorodoje, kur buvo atlikta užduotis.",
			},
			{
				id: 6,
				question:
					"Kaip galiu patikrinti, ar mano mokiniams išsiųsta nuoroda neturi klaidų?",
				answer: "Nukopijavę namų darbų nuorodą, galite atidaryti naują langą ir ją įklijuoti. Taip pat galėsite patikrinti, ar užduotyje nėra klaidų.",
			},
			{
				id: 7,
				question:
					"Kaip galiu išsiųsti namų darbus naujai klasei, jei dėstau dvi ar daugiau klasių vienu metu?",
				answer: "Paspauskite mygtuką „siųsti namų darbus naujai klasei“.",
			},
			{
				id: 8,
				question:
					"Kaip galiu patikrinti mokinių atliktas namų darbų užduotis?",
				answer: "Išsiuntę namų darbus, eikite į pagrindinį puslapį, kur pamatysite savo klasę. Šiame puslapyje paspauskite „išsiųstos namų darbų užduotys“, tada pasirinkite norimą peržiūrėti užduotį. Čia matysite mokinius, kurie atliko namų darbus.",
			},
			{
				id: 9,
				question: "Kaip galiu pranešti apie klaidą užduotyje?",
				answer: "Paspauskite mygtuką „Pranešti apie klaidą“ ir nurodykite klaidos turinį. Taip mes gausime pranešimą ir ištaisysime klaidą kuo greičiau.",
			},
			{
				id: 10,
				question:
					"Ką daryti, jei netyčia išsiunčiau neteisingą užduotį ar atsiliepimą?",
				answer: "Galite parašyti mums el. paštu info@edufy.pro, ir mūsų komanda padės jums kuo greičiau.",
			},
		],
		[]
	);
};

export const useQuestionsWithAnswersForEnglish = () => {
	return useMemo(
		() => [
			{
				id: 0,
				question:
					"Where do I find existing homework assignments which I can send to my students right away?",
				link: "",
				answer: "After you create your own account you will be taken directly to a page where you can choose a class  and a course. Then you click on 'Existing assignments', choose a topic and click on 'Send the assignment'. Copy the link and send it to your students via Teams, Messenger etc. In case you don't find a desired assignment, you can easily request it by clicking on 'Request homework'. Just indicate a topic and a course of an assignment that you wish to be added and our team will upload it on our website within 48 hours. If you want to create homework yourself, then simply choose a course and click on 'My assignments', where you can create a new assignment and send it to your students. ",
			},

			{
				id: 1,
				question: "How do I send existing assignments to my students?",
				answer: "First create your own account. When you log in you will be taken directly to a page where you can choose a class for which you want to create homework. After you choose a class you select a course and you will see 'Existing assignments'. Then choose a subject and press 'Save my assignment'. Finally, press the 'copy' button, copy the link and send it to your students via Teams, Messenger etc.",
			},
			{
				id: 11,
				question: "Can I request a desired topic of homework?",
				answer: "Yes, you can easily request it. just click on 'Request homework' and our team will upload it on our website within 48 hours.",
			},
			{
				id: 12,
				question: "How can I create a homework myself?",
				link: "",
				answer: "First create your own account. When you log in you will be taken directly to a page where you can choose a class for which you want to create homework. After you choose a class you select a course and you will see 'My assignments'. Then click on 'create a new assignment' and choose between 'multiple choice question' and 'open question'. Create your assignment and press 'Save my assignment'. Finally, press the 'copy' button, copy the link and send it to your students via Teams, Messenger etc.",
			},

			{
				id: 2,
				question:
					"How do I send homework assignments via Teams or Messenger?",
				answer: "When you create an assignment, press the 'copy' button. Open the chat of Teams or Messenger, paste the link and send it to your students.",
			},
			{
				id: 3,
				question: "How can I send feedback to my students?",
				answer: "Go to 'sent homework assignments' and press 'Close the assignment and send feedback'. Students can consult feedback via the same link where the assignment was made.",
			},
			{
				id: 4,
				question: "How can a student complete the assignment?",
				answer: "Via the link that was sent by a teacher, a student enters his/her name and immediately fills in the homework assignment. Once the assignment is completed, he/she clicks on 'send the assignment' to send it to a teacher. A student can sign up to save submitted homework assignments, but it is also possible to complete assignments without an account.",
			},
			{
				id: 5,
				question: "Where can a student view teacher's feedback?",
				answer: "After a teacher closes an assignment and sends feedback, it will appear on the same link where the assignment was completed.",
			},
			{
				id: 6,
				question:
					"How can I check that the link which I have sent to my students doesn't contain any errors?",
				answer: "After copying the homework assignment link, you can open a new window and paste it there. This way you can see whether or not your assignment contains any errors.",
			},
			{
				id: 7,
				question:
					"How can I send homework to a new class, if I teach two or more classes at the same time?",
				answer: "You press the button 'send homework to a new class'.",
			},
			{
				id: 8,
				question:
					"How do I check homework assignments which are completed by my students?",
				answer: "After you have sent homework, check the main page and you will see your class. On this page, press 'sent homework assignments', then click on homework which you wish to view. There you will see students who have completed homework.",
			},
			{
				id: 9,
				question:
					"How can I report if there's an error in an assignment?",
				answer: "Press the 'Report an error' button and indicate a content of an error. This way we will get notified  and we will handle it as soon as possible.",
			},
			{
				id: 10,
				question:
					"What can I do if I have accidently sent a wrong assignment or feedback?",
				answer: "You can send us an email to info@edufy.pro and our team will help you as soon as possible.",
			},
		],
		[]
	);
};
