import { IRUser } from "@app/api/users/helper-schemas";
// import { WebsiteOrigin } from "@app/globals";

export class CountriesService {
	// getAvailableLanguagesForCountry
	getCountiesForLanguage(language: string) {
		if (language === "ka") {
			return ["geo"];
		} else if (language === "lt") {
			return ["ltu"];
		} else if (language === "bg") {
			return ["bgr"];
		} else if (language === "az") {
			return ["aze"];
		}
		return ["ltu", "bgr", "aze", "geo"];
	}

	getAllCounties() {
		return ["ltu", "bgr", "aze", "geo"];
	}

	getMinAndMaxGrades(
		country: IRUser["country"],
		webOrigin: IRUser["registrationOrigin"]
	): { minGrade: number; maxGrade: number } {
		if (country === "ltu") {
			return { minGrade: 1, maxGrade: 6 };
		}
		return { minGrade: 1, maxGrade: 12 };
	}
}
