import React from "react";
import termStyles from "./styles/terms.module.css";
import { useLocale } from "@app/hooks/intl";
import { LithuanianTerms } from "./lit-terms";
import { EngTerms } from "./eng-terms";
import { GeoTermsContent } from "./geo-terms";

export const Terms = () => {
	const isGeo = useLocale() === "ka";
	const isLithuanian = useLocale() === "lt";
	return (
		<>
			{isGeo ? (
				<GeoTerms />
			) : isLithuanian ? (
				<LithuanianTerms />
			) : (
				<EngTerms />
			)}
		</>
	);
};

export const GeoTerms = () => {
	return (
		<>
			<div className={termStyles.container}>
				<h1 className={termStyles.title}>
					ვებგვერდის გამოყენების წესები და პირობები
				</h1>
				<GeoTermsContent />
			</div>
		</>
	);
};
