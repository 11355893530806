import React from "react";
import {
	useQuestionsWithAnswersForStudents,
	useQuestionsWithAnswersForTeachers,
	useQuestionsWithAnswersForEnglish,
	useQuestionsWithAnswersForLithuanian,
} from "@app/hooks/faq";
import { useBoolean } from "@app/hooks/general";
import styles from "./styles/faq.module.css";
import { HalfMoon } from "./half-moon";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ReactComponent as VideoIcon } from "./styles/imgs/video.svg";
import { VideoInstruction } from "../tv-school-landing/video-instruction";
import { useClassroomsUser } from "@app/hooks/users";
import { useWindowSize } from "@app/hooks/front";
import { FormattedMessage } from "react-intl";
import { getFormattedMessage } from "@app/utils/locale";
import { useLocale } from "@app/hooks/intl";
import classnames from "classnames";

export const FaqContainer: React.FC<{
	isOnlyTitleWithoutHalfMoon?: boolean;
}> = (props) => {
	const user = useClassroomsUser();
	const isEng = useLocale() === "en";
	const isLithuanian = useLocale() === "lt";
	const isGeo = useLocale() === "ka";
	const allQuestionsForStudent = useQuestionsWithAnswersForStudents();
	const allQuestionsForTeacher = useQuestionsWithAnswersForTeachers();
	const questionsForEnglish = useQuestionsWithAnswersForEnglish();
	const questionsForLithuanian = useQuestionsWithAnswersForLithuanian();
	let allQuestions = allQuestionsForTeacher;
	if (isLithuanian) {
		allQuestions = questionsForLithuanian;
	} else if (isEng) {
		allQuestions = questionsForEnglish;
	} else if (isGeo) {
		allQuestions = user?.isStudent()
			? allQuestionsForStudent
			: allQuestionsForTeacher;
	}

	return (
		<div className={styles.faqWithMoon}>
			{props.isOnlyTitleWithoutHalfMoon ? (
				<div className={styles.faqTitle}>
					<FormattedMessage id="faqLong" />
				</div>
			) : (
				<HalfMoon title={getFormattedMessage("faqLong")} />
			)}

			<div className={styles.faqContainer}>
				{allQuestions.map((q, i) => (
					<QuestionWithAnswer
						key={q.id}
						question={q.question}
						answer={q.answer}
						id={q.id}
						videoLink={q.link}
					/>
				))}
			</div>
		</div>
	);
};

const QuestionWithAnswer: React.FC<{
	question: string;
	answer?: string;
	videoLink?: string;
	id: number;
}> = (props) => {
	const { width } = useWindowSize();
	const isMobile = width < 600;

	const { value: isAnswerVissible, switchValue: switchAnswer } = useBoolean(
		props.id === 0 && !isMobile
	);

	const {
		value: isVideoVissible,
		setTrue: setVideoVissible,
		setFalse: hideVideo,
	} = useBoolean(false);

	return (
		<div
			className={styles.questionWithAnswerContainer}
			onClick={switchAnswer}
		>
			<div className={styles.topHalf}>
				<div className={styles.questionWithArrowIcon}>
					<div className={styles.question}>{props.question}</div>
					{!isAnswerVissible ? (
						<ArrowDownIcon className={styles.arrow} />
					) : (
						<ArrowUpIcon className={styles.arrow} />
					)}
				</div>

				<section
					className={classnames(
						styles.answer_wrapper,
						isAnswerVissible && styles.show_answer
					)}
				>
					<div className={styles.line} />
					<div className={styles.answer}> {props.answer} </div>
				</section>
			</div>
			{isAnswerVissible && (
				<>
					{props.videoLink && (
						<div
							className={styles.videoAnswer}
							onClick={setVideoVissible}
						>
							<VideoIcon className={styles.videoIcon} />
							ვიდეოპასუხი
						</div>
					)}
					{isVideoVissible && (
						<VideoInstruction
							videoId={props.videoLink}
							onClose={hideVideo}
						/>
					)}
				</>
			)}
		</div>
	);
};
