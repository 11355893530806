import { Subscription } from "./utils/subscription";
import { extractRootDomain, extractHostname } from "./utils/urls";

export enum WebsiteOrigin {
	hubu = "hubu",
	tvSchool = "tv-school",
	weeklyTests = "weekly",
}

export interface SubWebsiteOrigin {
	readonly main: null;
	readonly britishCenter: "bc";
}
export const SubWebsiteOrigin: SubWebsiteOrigin = {
	main: null,
	britishCenter: "bc",
};

export const WebsiteOrigins = [
	WebsiteOrigin.hubu,
	WebsiteOrigin.tvSchool,
	WebsiteOrigin.weeklyTests,
];

export const DomainsByWebsiteOrigins: Record<WebsiteOrigin, string[]> = {
	[WebsiteOrigin.hubu]: ["hubu.ge"],
	[WebsiteOrigin.tvSchool]: ["edufy.pro", "lt.edufy.pro"],
	[WebsiteOrigin.weeklyTests]: ["შემაჯამებელი"],
};

export const isTVSchool = () => {
	return extractRootDomain(window.location.href) === "edufy.pro";
};

export const isLocalhost = () => {
	return (
		window.location.href
			.substr(window.location.href.indexOf("://") + 3)
			.indexOf("localhost") === 0
	);
};

const defaultOrigin =
	(process.env.REACT_APP_DEFAULT_ORIGIN as WebsiteOrigin | undefined) ||
	(sessionStorage.getItem("websiteOrigin") as WebsiteOrigin) ||
	WebsiteOrigin.tvSchool;

export const getCurrentWebsite = (): WebsiteOrigin => {
	if (isTVSchool()) {
		return WebsiteOrigin.tvSchool;
	}
	return defaultOrigin;
};

export const getCurrentSubWebsite = () => {
	return SubWebsiteOrigin.main;
};

export const getInitialLocale = () => {
	if (localStorage.locale) {
		return localStorage.locale;
	}
	if (extractHostname(window.location.href) === "lt.edufy.pro") {
		return "lt";
	}
	return "en";
};

export const techDiffForStudents =
	typeof process.env.REACT_APP_STUDENTS_TECH_DIFF === "string" &&
	process.env.REACT_APP_STUDENTS_TECH_DIFF.toLowerCase() === "true";

export const fbSubscription = new Subscription({
	loaded: false,
});
fbSubscription.subscribe((data) => {
	fbSubscription.setMetaData({
		loaded: true,
	});
});
export const broadcastFBLoad = () => {
	fbSubscription.broadcast(null);
};
