import React from "react";
import termStyles from "./styles/terms.module.css";

export const LithuanianTerms = () => {
	return (
		<>
			<div className={termStyles.container}>
				<h1 className={termStyles.dutchTitle}>
					Šis dokumentas apibrėžia interneto platformos Edufy.pro
					bendrąsias naudojimo sąlygas.
				</h1>
				<p className={termStyles.primary}>
					Šiose bendrosiose naudojimo sąlygose šios sąvokos turi šias
					reikšmes:
				</p>
				<p>
					Vartotojas: bet kuris fizinis ar juridinis asmuo
					(registruotas ar ne), kuris naudojasi svetaine arba bet
					kokiu būdu naudojasi edufy.pro, pavyzdžiui, peržiūrėdamas ar
					įkeldamas (mokymosi) medžiagą.
				</p>
				<p>Svetainė: www.edufy.pro ir visi jos komponentai.</p>
				<p>
					(Mokymosi) medžiaga: visa medžiaga, kurią vartotojas įkelia
					į svetainę, arba užduotys, kurias vartotojas sukuria pats.
					<br />
					„Mes“, „Mūsų“, „Mus“: Edufy.pro komanda
					<br />
					Šios bendrosios naudojimo sąlygos yra teisinė sutartis tarp
					svetainės savininko – „Edufy A-I LLC“ (toliau – edufy.pro) –
					ir svetainės vartotojų.
				</p>
				<p className={termStyles.primary}>
					Naudodamasis svetaine, vartotojas patvirtina, kad yra ne
					jaunesnis kaip 18 metų arba, jei yra nepilnametis, turi tėvų
					ar teisėto globėjo leidimą. Vartotojas turi patvirtinti, kad
					perskaitė bendrąsias naudojimo sąlygas, su jomis sutinka ir
					prisiima visą atsakomybę už jų laikymąsi.
				</p>

				<p className={termStyles.termHeader}>1. Registracija</p>
				<div className={termStyles.termText}>
					<p>
						1.1. Vartotojas privalo pateikti teisingą informaciją
						kurdamas paskyrą registracijos metu.
					</p>
					<p>
						1.2. Vartotojas neturi dalintis savo paskyros
						informacija su trečiosiomis šalimis ar leisti kitiems
						asmenims ja naudotis.
					</p>
					<p>
						1.3. Vartotojas negali atskleisti registracijos metu
						nurodytos informacijos ar perduoti jos trečiosioms
						šalims.
					</p>
					<p>
						1.4. Vartotojas turi pats atnaujinti savo profilio
						informaciją.
					</p>
					<p>
						1.5. Vartotojas patvirtina, kad svetainės naudojimas
						nepažeidžia vietinių, nacionalinių ar tarptautinių
						įstatymų ir nepažeidžia trečiųjų šalių teisių.
					</p>
				</div>

				<p className={termStyles.termHeader}>
					2. (Mokymosi) medžiagos įkėlimas į svetainę
				</p>
				<div className={termStyles.termText}>
					<p>
						2.1. Vartotojai, registruoti kaip mokytojai (toliau –
						mokytojai), gali kurti užduotis ir skelbti jas
						svetainėje.
					</p>
					<p>
						2.2. Mokytojas yra atsakingas už jo sukurtų ir/ar įkeltų
						užduočių turinį.
					</p>
					<p>
						2.3. Įkeldamas (mokymosi) medžiagą į svetainę,
						vartotojas patvirtina ir garantuoja, kad:
					</p>
					<p>
						2.3.1. Įkeldama(s) medžiagą, ji(ne) nepažeidžia
						teritorinių, nacionalinių ar tarptautinių įstatymų.
					</p>
					<p>
						2.3.2. Ji(ne) nepažeidžia trečiųjų šalių teisių,
						įskaitant autorių teises, prekės ženklus, patentus,
						intelektinę nuosavybę ar asmens duomenų apsaugą.
					</p>
					<p>
						2.3.3. Medžiaga neturi šmeižto, įžeidimų, grasinimų ar
						smurto kurstymo.
					</p>
					<p>
						2.4. Įkeldamas medžiagą, mokytojas suteikia edufy.pro
						teisę ja naudotis bet kokiu tikslu, įskaitant
						komercinius tikslus, ir perduoti šią teisę trečiosioms
						šalims.
					</p>
				</div>

				<p className={termStyles.termHeader}>3. Autorių teisės</p>
				<div className={termStyles.termText}>
					<p>
						3.1. Svetainėje esančios (mokymosi) medžiagos yra
						edufy.pro intelektinė nuosavybė ir yra saugomos Gruzijos
						įstatymų.
					</p>
					<p>
						3.2. Svetainėje esančią medžiagą galima naudoti tik
						svetainėje, nekomerciniais tikslais.
					</p>
					<p>
						3.3. Vartotojas neturi teisės atkurti, publikuoti,
						keisti ar platinti svetainės medžiagos be edufy.pro
						leidimo.
					</p>
				</div>

				<p className={termStyles.termHeader}>4. Atsakomybė</p>
				<div className={termStyles.termText}>
					<p>
						4.1. Edufy.pro neatsako už žalą, atsiradusią dėl
						svetainės veikimo sutrikimų.
					</p>
					<p>
						4.2. Edufy.pro neatsako už žalą, kurią sukėlė vartotojas
						naudodamasis svetaine.
					</p>
					<p>
						4.3. Vartotojas yra atsakingas už bet kokią žalą, kurią
						jis/ji sukelia naudodamasis svetaine.
					</p>
					<p>
						4.4. Jei vartotojas pažeidžia šias taisykles, edufy.pro
						turi teisę apriboti prieigą prie svetainės arba ištrinti
						paskyrą.
					</p>
				</div>

				<p className={termStyles.termHeader}>5. Privatumo politika</p>
				<div className={termStyles.termText}>
					<p>
						5.1. Naudodamasis svetaine, vartotojas patvirtina, kad
						yra susipažinęs ir sutinka su privatumo politikos
						nuostatomis.
					</p>
					<p>
						5.2. Privatumo politika yra šių bendrųjų naudojimo
						sąlygų dalis ir visi jų punktai yra taikomi.
					</p>
				</div>

				<p className={termStyles.termHeader}>6. Kompensacija</p>
				<div className={termStyles.termText}>
					<p>
						6.1. Vartotojas turi kompensuoti visą žalą, kurią
						sukelia edufy.pro dėl neteisingo svetainės naudojimo ar
						taisyklių pažeidimo.
					</p>
				</div>

				<p className={termStyles.termHeader}>
					7. Naudojimo sąlygų keitimas
				</p>
				<div className={termStyles.termText}>
					<p>
						7.1. Edufy.pro turi teisę bet kuriuo metu keisti ar
						papildyti naudojimo sąlygas be išankstinio vartotojų
						įspėjimo.
					</p>
				</div>

				<p className={termStyles.termHeader}>8. Papildomos sąlygos</p>
				<div className={termStyles.termText}>
					<p>
						8.1. Šios naudojimo sąlygos yra reglamentuojamos
						Gruzijos teisės, o visi ginčai sprendžiami Tbilisio
						miesto teisme.
					</p>
					<p>
						8.2. Svetainė skirta naudoti Indijoje, Bangladeše ir
						Pakistane. Jei vartotojas naudojasi svetaine kitose
						teritorijose, jis/ji atsako už vietinių įstatymų
						laikymąsi.
					</p>
					<p>
						8.3. Jei viena ar daugiau šių bendrųjų naudojimo sąlygų
						nuostatų tampa negaliojančiomis ar neįgyvendinamomis,
						tai neturi įtakos kitoms sąlygų nuostatoms.
						Neįgyvendinama taisyklė ar nuostata bus pakeista kita,
						kuri labiausiai atitinka pradinės nuostatos tikslą ir
						esmę.
					</p>
				</div>
			</div>
		</>
	);
};
