import { addLoader } from "@app/common-javascript";
import React from "react";
import { FormattedMessage } from "react-intl";

export const defaultComponentWrapper = (
	param: any
): React.LazyExoticComponent<any> => {
	if (typeof param !== "function") return param;
	return React.lazy(() => {
		try {
			const result = param();
			return result.catch((e) => {
				console.error(e);
				return { default: ModuleNotFoundErrorComponent };
			});
		} catch (e) {
			return Promise.resolve({ default: param });
		}
	});
};

const ModuleNotFoundErrorComponent = () => {
	const reload = () => {
		addLoader();
		window.location.reload();
	};
	return (
		<div className="main mt-5 text-center">
			<h1 className="text-xl">
				<FormattedMessage id="errorAlert" />.{" "}
				<FormattedMessage id="errorAlertRefreshPage" />
			</h1>
			<div className="h-5" />
			<button
				onClick={reload}
				className="p-3 rounded-xl font-roboto-geo-caps max-w-full sm:text-lg focus:outline-none bg-primary hover:bg-primary-700 focus:bg-primary-900 text-white"
			>
				<FormattedMessage id="refreshPageButton" />
			</button>
		</div>
	);
};
