import React from "react";
import termStyles from "./styles/terms.module.css";
import { useLocale } from "@app/hooks/intl";

export const LithuanianConfidentiality = () => {
	return (
		<>
			<div className={termStyles.container}>
				<h1 className={termStyles.dutchTitle}>
					Šis dokumentas apibrėžia interneto platformos Edufy.pro
					privatumo politiką.
				</h1>
				<p className={termStyles.primary}>
					Šioje privatumo politikoje šios sąvokos turi šias reikšmes:
				</p>
				<p>
					Vartotojas: bet kuris fizinis ar juridinis asmuo
					(registruotas ar ne), kuris naudojasi svetaine ar bet kokiu
					būdu naudojasi edufy.pro, pavyzdžiui, peržiūrėdamas ar
					įkeldamas medžiagą.
					<br />
					Svetainė: www.edufy.pro ir visi jos komponentai.
					<br />
					(Mokymosi) medžiaga: visa medžiaga, kurią vartotojas įkelia
					į svetainę, arba užduotys, kurias vartotojas sukuria pats.
				</p>
				<p>
					„Mes“, „Mūsų“, „Mus“: Edufy.pro komanda <br />
					Šios privatumo politikos taisyklės yra teisinis susitarimas
					tarp svetainės savininko – „Edufy A-I LLC“ (toliau –
					edufy.pro) – ir svetainės vartotojų.
				</p>
				<p>
					Naudodamasis svetaine, vartotojas patvirtina, kad yra ne
					jaunesnis kaip 18 metų arba, jei yra nepilnametis, turi tėvų
					ar teisėto globėjo leidimą. Vartotojas turi patvirtinti, kad
					perskaitė privatumo politiką, su ja sutinka ir prisiima visą
					atsakomybę už jos laikymąsi.
				</p>
				<p>
					Mūsų komanda įsipareigoja saugoti jūsų privatumą ir laikytis
					jūsų asmens duomenų apsaugos pagal Europos Sąjungos Bendrąjį
					duomenų apsaugos reglamentą (BDAR). Šioje privatumo
					politikoje paaiškiname, kada ir kodėl renkame jūsų asmens
					duomenis bei kaip juos naudojame.
				</p>

				<p className={termStyles.secondary}>Kas mes esame?</p>
				<p className={termStyles.marginTB}>
					Edufy.pro yra „Edufy A-I LLC“ projektas, kurio tikslas –
					sukurti įdomią internetinę mokymosi aplinką ir palengvinti
					namų darbų kūrimą. Mūsų įmonės būstinė yra adresu: 50 Giorgi
					Chubinashvili g., Tbilisis, Gruzija. <br />
					Galite susisiekti su mumis el. paštu: info@edufy.pro
				</p>

				<p className={termStyles.secondary}>Asmens duomenys</p>
				<p className={termStyles.marginTB}>
					Mes tvarkome jūsų asmens duomenis, kai registruojatės
					svetainėje, naudojatės ja arba susisiekiate su mumis el.
					paštu. Registracijos metu tvarkome šią informaciją: <br />
					Jūsų vardas ir pavardė <br />
					Jūsų el. pašto adresas <br />
					Taip pat naudojame vadinamuosius žurnalus. Kai vartotojas
					lankosi svetainėje, serveryje saugoma ši informacija:
					svetainės pasiekimo data ir būdas, interneto protokolo (IP)
					adresas, nukreipiantysis adresas ir aplankyto šaltinio
					adresas.
				</p>

				<p className={termStyles.secondary}>Google Analytics</p>
				<p>
					Edufy.pro naudoja „Google Analytics“ svetainės lankomumo
					statistikai rinkti. Pavyzdžiui, mes galime analizuoti
					svetainės statistiką, puslapių peržiūrų skaičių, vartotojų
					kilmės vietą (šalis / miestas), vidutinį apsilankymo laiką
					ir kt. Šios paslaugos tikslas – statistiškai apdoroti
					informaciją ir geriau suprasti mūsų svetainės lankytojus.
					Techninė vartotojo identifikacija yra neįmanoma.
				</p>

				<p className={termStyles.secondary}>Slapukai</p>
				<p>
					Mes naudojame slapukus. Slapukai – tai mažos tekstinės
					rinkmenos, kurios saugo tam tikrą informaciją. Kai
					vartotojas lankosi svetainėje, slapukai gali būti įrašyti į
					jo kompiuterį, planšetę ar išmanųjį telefoną. Pagrindinė
					slapukų funkcija – atskirti vieną vartotoją nuo kito.
					Slapukai saugo informaciją apie jūsų apsilankymą svetainėje.
					Jie leidžia mums atpažinti vartotoją kitą kartą apsilankius
					svetainėje, todėl nereikia kaskart prisijungti iš naujo. Kai
					vartotojas lankosi mūsų svetainėje, mes matome šią
					informaciją: nukreipiantis URL, vartotojo apsilankymo
					laikas, jo naudojami raktiniai žodžiai, operacinė sistema,
					įrenginys, naršyklės IP adresas ir, jei nurodyta, vartotojo
					vardas ir el. pašto adresas. Ši informacija padeda mums
					pagerinti svetainės veikimą. <br />
					Naudojame slapukus, kad vartotojai galėtų lengviau naršyti
					tarp skirtingų mūsų svetainės puslapių, pagerintume bendrą
					vartotojo patirtį ir pateiktume informaciją patogiausiu
					formatu.
				</p>

				<p className={termStyles.secondary}>
					Kodėl renkame ir naudojame jūsų asmens duomenis?
				</p>
				<p>
					Mes tvarkome vartotojų asmens duomenis tam, kad galėtume
					teikti paslaugą, kurios buvo prašoma. Taip pat siekiame
					palengvinti svetainės naudojimą, rinkodaros ir statistikos
					tikslais bei palaikyti ryšį su vartotojais.
				</p>

				<p className={termStyles.secondary}>
					Kaip ilgai saugome jūsų asmens duomenis?
				</p>
				<p>
					Asmens duomenys saugomi tiek, kiek tai būtina tikslui,
					kuriam jie buvo surinkti, pasiekti. <br />
					Jei jūsų asmens duomenų saugojimas tampa nebereikalingas,
					mes juos ištrinsime arba pakeisime taip, kad jie nepažeistų
					jūsų privatumo.
				</p>

				<p className={termStyles.secondary}>Saugumas</p>
				<p>
					Mes įdiegėme visas pagrįstas technines apsaugos priemones,
					kad jūsų asmens duomenys, esantys mūsų svetainėje, būtų
					apsaugoti nuo neteisėtos prieigos, naudojimo, praradimo ar
					sunaikinimo. <br />
					Registruodamasis svetainėje, vartotojas privalo pasirinkti
					stiprų slaptažodį ir jo neatskleisti kitiems.
				</p>

				<p className={termStyles.secondary}>
					Ar perduodame jūsų asmens duomenis kitiems?
				</p>
				<p>
					Mes galime perduoti jūsų asmens duomenis mūsų partneriams
					šioje privatumo politikoje nurodytais tikslais. Taip pat
					galime perduoti jūsų duomenis valstybės institucijoms pagal
					galiojančius įstatymus ar teismo sprendimą.
				</p>

				<p className={termStyles.secondary}>
					Jūsų teisės dėl asmens duomenų
				</p>
				<p>
					Jūs turite teisę paprašyti kopijos asmens duomenų, kuriuos
					apie jus turi edufy.pro. <br />
					Jūs turite teisę prašyti edufy.pro ištaisyti jūsų asmens
					duomenis, jei jie yra neteisingi ar pasenę. <br />
					Jūs galite prašyti edufy.pro ištrinti jūsų asmens duomenis.{" "}
					<br />
				</p>
			</div>
		</>
	);
};
